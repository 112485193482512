<template>
  <page :title="i18n.title">
    <div class="wallet-card">
      <p class="savetitle">{{ i18n.savecard }}</p>
      <p class="savedic">{{ i18n.savedic }}</p>
      <!-- <div class="addcard" @click="payListShow = true">
        <div class="left">
          <img src="../../assets/imgs/icon_creditcard.png" alt="">
          <span class="addcard-msg">{{ i18n.addcard }}</span>
        </div>
        <img src="../../assets/imgs/icon_more.png" alt="">
      </div> -->
      <div class="paylistitem" @click="saveCard('card')">
        {{ i18n.newcard }}
      </div>
      <div class="paylistitem" @click="saveCard('paypal')">
        {{ i18n.paypal }}
      </div>
      <div class="confirm-scroll-y">
        <div class="confirm-card-list" v-for="item in cardList" :key="item.pId">
          <p class="value">
            <span class="pBrand">{{ item.pBrand }}</span>
            <span>{{ item.pCardnumber }}</span>
          </p>
          <span class="delete" @click="removeCardConfirm(item.pId)">{{
            i18n.delete
          }}</span>
        </div>
      </div>
    </div>
    <popup-warp v-model="payListShow">
      <div class="savebottom"></div>
    </popup-warp>
    <popup-warp v-model="isCreditcard">
      <div>
        <div class="creditcard-input">
          <div id="credit-card-element"></div>
        </div>
        <div class="btn creditcard-btn" @click="onStripeSetup">
          {{ i18n.savenow }}
        </div>
      </div>
    </popup-warp>
  </page>
</template>

<script>
import { mapState } from "vuex"
import {
  USER_CARD,
  PAYMODE,
  SAVED_CARD_RENT,
  REMOVE_CARD,
  STRIPE_SAVE_CARD_CONFIRM,
  STRIPE_SAVE_CARD,
  PAYPAL_SAVE_CARD
} from "../../apis/payment"
import stripeMixin from "../../mixins/stripe"
import { PopupWarp } from "../../components/popup"
export default {
  mixins: [stripeMixin],
  components: {
    PopupWarp
  },
  computed: {
    ...mapState(["system", "cabinet", "user", "qrcode"]),
    i18n() {
      return this.$t("wallet_card")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    currency() {
      if (this.cabinet) {
        return this.cabinet.currencyName
      } else {
        return this.user.pcurrencyName
      }
    }
  },
  watch: {},
  data() {
    return {
      price: 0,
      payType: "YJ",
      payMode: {
        pPaymentType: "STRIPE_CARD"
      },
      payPalMode: {
        pPaymentType: "PAYPAL"
      },
      typestr: "card",
      disabled: false,
      isCreditcard: false,
      payListShow: false,
      creditcard: null,
      creditOrder: null,
      debitOrder: null,
      isDebitcard: false,
      isConfirm: false,
      debitcard: null,
      cardList: [],
      payModeList: []
    }
  },
  methods: {
    getCardList() {
      this.$loading(true)
      this.$get(
        USER_CARD,
        {
          status: 1,
          qrcode: this.qrcode
        },
        (resp) => {
          this.$loading(false)
          console.log("cardlist", resp)
          this.cardList = resp.data
          if (resp.data.length > 0 && this.isConfirm && this.qrcode) {
            this.onCardBorrow()
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getCardList()
          })
          console.log(error)
        }
      )
    },
    getPayMode() {
      this.$loading(true)
      this.$get(
        this.qrcode ? `${PAYMODE}?id=${this.qrcode}` : PAYMODE,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            let list = resp.data
            let arr = list.filter((v) => {
              return v.pPaymentType === "STRIPE_CARD"
            })
            let paypalarr = list.filter((v) => {
              return v.pPaymentType === "PAYPAL"
            })
            if (arr && arr.length > 0) {
              this.payMode = arr[0]
              this.loadStripeScript(this.payMode.publicKey)
            }
            if (paypalarr && paypalarr.length > 0) {
              this.payPalMode = paypalarr[0]
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        }
      )
    },
    saveCard(type) {
      this.typestr = type
      console.log("this.payMode", this.payMode)
      let url = `${STRIPE_SAVE_CARD}?paymentId=${this.payMode.pId}`
      if (this.typestr === "paypal") {
        url = `${PAYPAL_SAVE_CARD}?paymentId=${this.payPalMode.pId}`
        this.$store.commit("paychannel", this.payPalMode.pId)
      }
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          if (this.typestr === "paypal") {
            if (this.cabinet.qrcode) {
              sessionStorage.setItem("qrcode_cabinetId", this.cabinet.qrcode)
            }
            window.location.href = resp.data.approve
          } else {
            this.creditOrder = resp.data
            this.payListShow = false
            this.onLoadElement()
          }
          console.log("saveCard====", resp, this.typestr)
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard(type)
          })
          console.log(error)
        }
      )
    },
    onLoadElement() {
      const options = {
        clientSecret: this.creditOrder.clientSecret,
        appearance: {
          theme: "stripe"
        }
      }
      if (window.stripe) {
        // this.loadStripeScript(this.payMode.publicKey)
        this.creditcard = window.stripe.elements(options)
        const paymentElement = this.creditcard.create("payment")
        this.isCreditcard = true
        paymentElement.mount("#credit-card-element")
      } else {
        this.$toast("loading...")
        this.loadStripeScript()
        setTimeout(() => {
          this.onLoadElement()
        }, 500)
      }
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      let { query } = this.$route
      let pid = this.payMode.pId
      const _q = Object.keys(query).length > 0 ? "&" : "?"
      const returnUrl =
        window.location.href +
        `${_q}paymentId=${pid}&pmid=${this.creditOrder.paymentId}&type=${this.typestr}`
      window.stripe
        .confirmSetup({
          elements: this.creditcard,
          confirmParams: { return_url: returnUrl }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          } else {
            console.log("成功====", result)
          }
        })
    },
    onCardBorrow() {
      // this.$router.replace(`/?qrcode=${this.qrcode}`)
      this.$loading(true)
      this.$get(
        `${SAVED_CARD_RENT}?qrcode=${this.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data.out_trade_no) {
            this.$router.replace(`/result?tradeNo=${resp.data.out_trade_no}`)
          } else {
            this.$confirm({
              title: this.$t("home.msg_fail_title"),
              content: this.$t("home.msg_fail_content"),
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          // this.$toast(error)
          console.log(error)
        }
      )
    },
    onConfirmSaveCard(pid, id) {
      this.$loading(true)
      this.$post(
        STRIPE_SAVE_CARD_CONFIRM,
        {
          paymentId: pid,
          paymentMethod: id
        },
        (resp) => {
          setTimeout(() => {
            this.$loading(false)
            console.log("saveCardConfirm====", resp)
            this.isConfirm = true
            this.getCardList()
          }, 1500)
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onConfirmSaveCard(pid, id)
          })
          console.log(error)
        }
      )
    },
    removeCardConfirm(id) {
      this.$confirm({
        title: this.i18nMsg.msg_fail_title,
        content: this.i18nMsg.deletecard,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.removeCard(id)
      })
    },
    removeCard(id) {
      this.$loading(true)
      this.$get(
        `${REMOVE_CARD}/${id}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log("removecard====", resp)
          this.isConfirm = false
          this.getCardList()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.removeCard(id)
          })
          console.log(error)
        }
      )
    }
  },
  created() {
    // this.loadStripeScript()
    let { paymentId, pmid } = this.$route.query
    // let arr = window.location.href.includes('&') ? window.location.href.split('&') : []
    // let splitstr = ''
    if (paymentId) {
      // splitstr = arr[1].split('=')[1]
      this.onConfirmSaveCard(paymentId, pmid)
    } else {
      this.getCardList()
    }
    console.log(this.$route.query)
    this.getPayMode()
  }
}
</script>

<style lang="less">
.wallet-card {
  overflow-y: scroll;
  padding: 0 30px;
  font-family: "Montserrat-Regular";
  .savetitle {
    text-align: start;
    font-weight: 500;
  }
  .savedic {
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
  }
  .addcard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  .confirm-scroll-y {
    max-height: 880px;
    margin: 20px 0 0;
    overflow-y: scroll;
  }
  .confirm-card-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 3px 6px 10px #e8e8e8;
    padding: 10px 20px;
    margin: 20px 0;
    p {
      margin: 0;
    }
    .value {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      color: #00904a;
      font-weight: 500;
      .pBrand {
        font-size: 40px;
        margin-bottom: 10px;
        font-family: "Montserrat-Medium";
      }
    }
    .delete {
      color: red;
      font-size: large;
    }
  }
}
.paylistitem {
  // border: solid 1px #00904A;
  background: #000;
  color: #fff;
  border-radius: 20px;
  padding: 25px 0;
  text-align: center;
  margin: 30px 0;
}
.creditcard-input {
  margin: 48px 48px 48px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
  font-family: "Montserrat-Regular";
}
.creditcard-btn {
  margin: 48px;
  font-family: "Montserrat-Regular";
}
</style>
